import { getURLParams } from "@/lib/MapHelper"
import axios, { AxiosResponse } from "axios"
import { signOut } from "next-auth/react"
import { ReadonlyURLSearchParams } from "next/navigation"
import { toast } from "sonner"

export interface Result<T = any> {
    status?: number
    newToken?: any
    isSuccess?: boolean
    isTokenExpired?: boolean
    isPasswordExpired?: boolean
    headers?: any
    data?: T
    message?: any
    errors?: any
}

export interface QueryProps {
    enableHook?: boolean
    hdr_id?: number | string
    id?: number | string
    filters?: any
    page?: number
    perPage?: number
    param?: any
    onSuccess?: (data: any) => void
}

export interface PaginatedResult<T> {
    current_page: number
    data: T[]
    first_page_url: string
    from: number
    last_page: number
    last_page_url: string
    links: {
        url: string | null
        label: string
        active: boolean
    }[]
    next_page_url: string | null
    path: string
    per_page: number
    prev_page_url: string | null
    to: number
    total: number
}

type Params = { [key: string]: any }

const getApi = async (api: string, token: string | null, params: any = {}, contentType = "application/json") => {
    const headers: { [key: string]: string } = getHeaders(contentType, token)

    try {
        const response = await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_API_URL}/${api}`, {
            params,
            headers,
            validateStatus: () => true,
        })

        return handleApiResponse(response)
    } catch (error: unknown) {
        return handleApiError(error)
    }
}

const postApi = async (api: string, token: string | null, body: any = {}, params: any = {}, contentType = "application/json") => {
    const headers: { [key: string]: string } = getHeaders(contentType, token)

    try {
        const response = await axios.post(`${process.env.NEXT_PUBLIC_BACKEND_API_URL}/${api}`, body, {
            params: getURLParams(params).toString(),
            headers,
            validateStatus: () => true,
        })

        return handleApiResponse(response)
    } catch (error: unknown) {
        return handleApiError(error)
    }
}

const putApi = async (api: string, token: string | null, body: any = {}, params: any = {}, contentType = "application/json") => {
    const headers = getHeaders(contentType, token)

    try {
        const response = await axios.put(`${process.env.NEXT_PUBLIC_BACKEND_API_URL}/${api}`, body, {
            params: getURLParams(params).toString(),
            headers,
            validateStatus: () => true,
        })

        return handleApiResponse(response)
    } catch (error: unknown) {
        return handleApiError(error)
    }
}

const deleteApi = async (api: string, token: string | null, params: any = {}, contentType = "application/json") => {
    const headers = getHeaders(contentType, token)

    try {
        const response = await axios.delete(`${process.env.NEXT_PUBLIC_BACKEND_API_URL}/${api}`, {
            params: getURLParams(params).toString(),
            headers,
            validateStatus: () => true,
        })

        return handleApiResponse(response)
    } catch (error: unknown) {
        return handleApiError(error)
    }
}

const downloadGetApi = async (api: string, token: string | null, getData: any = {}, contentType: string = "application/octet-stream") => {
    try {
        const headers = getHeaders(contentType, token)

        const response = await axios.get(`${process.env.NEXT_PUBLIC_BACKEND_API_URL}/${api}`, {
            params: getData,
            headers,
            responseType: "blob",
            validateStatus: () => true,
        })

        return handleDownloadApiResponse(response)
    } catch (error: unknown) {
        return handleApiError(error)
    }
}

const getHeaders = (contentType: string, token: string | null) => {
    const headers: { [key: string]: string } = {
        Accept: "application/json",
        "Content-Type": contentType,
    }

    if (token) {
        headers.Authorization = `Bearer ${token}`
    }

    return headers
}

const handleApiResponse = (response: AxiosResponse<any, any>): Result | Promise<Result> => {
    const newToken = response.headers.authorization

    if (response.status !== 200) {
        return handleApiSuccessError(response)
    }

    const { data, errors, message, success } = response.data

    if (!!response.data?.data && !!response.data?.success) {
        const isTokenExpired = data?.token === false
        const isPasswordExpired = data?.token === -35

        return {
            status: response.status,
            newToken,
            isSuccess: response.data?.success ?? true,
            isTokenExpired,
            isPasswordExpired,
            data,
            message,
            errors,
        }
    } else if (!success) {
        return Promise.reject({
            status: response.status,
            newToken,
            isSuccess: response.data.success ?? false,
            isTokenExpired: data?.token === false,
            isPasswordExpired: data?.token === -35,
            data,
            message,
            errors,
        })
    }

    return response
}

const handleDownloadApiResponse = async (response: AxiosResponse<any, any>) => {
    const { status, headers, data } = response
    let isSuccess = status === 200
    let isTokenExpired = false
    let isPasswordExpired = false
    let message = ""
    let respData = null
    const newToken = typeof headers.authorization === "string" ? headers.authorization : null

    if (isSuccess) {
        respData = data
    } else {
        const reader = new FileReader()
        reader.readAsText(data)
        const strJson = await new Promise<string>((resolve) => {
            reader.onload = () => resolve(reader.result as string)
        })
        const objJson = JSON.parse(strJson)

        isSuccess = typeof objJson.success === "boolean" ? objJson.success : false
        message = typeof objJson.message === "string" ? objJson.message : ""

        if (objJson.data && typeof objJson.data.token !== "undefined") {
            const { token } = objJson.data
            isTokenExpired = token === false
            isPasswordExpired = token === -35
            respData = objJson.data
        }
    }

    return {
        status,
        newToken,
        isSuccess,
        isTokenExpired,
        isPasswordExpired,
        data: respData,
        message,
        headers,
        errors: null,
    }
}

const handleApiError = (error: unknown) => {
    let status = 0
    let message = ""

    if (axios.isAxiosError(error)) {
        if (error.response) {
            status = error.response.status
        }

        if (status === 401) {
            message = "Authorization is required for this request."
        } else {
            message = error.message
        }
    } else if (error instanceof Error) {
        message = error.message
    }

    return Promise.reject({
        status,
        newToken: null,
        isSuccess: false,
        isTokenExpired: false,
        isPasswordExpired: false,
        data: null,
        message,
        errors: null,
    })
}

const handleApiSuccessError = (response: AxiosResponse<any, any>) => {
    const { message } = response.data

    if (response.status === 401) {
        signOut()
        toast.error("Session Expired")
    }

    return Promise.reject({
        isSuccess: false,
        message: message,
    })
}

const handleApiResponseMessage = (result: Result, silent?: boolean) => {
    if (silent || !result.message) return

    if (!result.isSuccess) {
        toast.error("Somethings went wrong.", { description: result.message })
    }
}

const toQueryString = (params: Params, searchParams?: ReadonlyURLSearchParams): string => {
    const mergedSearchParams = {
        ...Array.from(searchParams?.entries() ?? [])
            .map(([key, value]) => ({
                [key]: value,
            }))
            .reduce((acc, curr) => ({ ...acc, ...curr }), {}),
        ...params,
    }

    if (!mergedSearchParams) return ""

    const buildQueryString = (obj: Params, prefix = ""): string => {
        return Object.keys(obj)
            .filter((key) => obj[key] !== null && obj[key] !== undefined && obj[key] !== "")
            .map((key) => {
                const value = obj[key]
                const paramKey = prefix ? `${prefix}[${key}]` : key

                if (value === null || value === undefined || value === "") {
                    return ""
                } else if (Array.isArray(value)) {
                    return value.map((v) => `${encodeURIComponent(paramKey)}[]=${encodeURIComponent(v)}`).join("&")
                } else if (typeof value === "object") {
                    return buildQueryString(value, paramKey)
                }
                return `${encodeURIComponent(paramKey)}=${encodeURIComponent(value)}`
            })
            .filter((param) => param !== "")
            .join("&")
    }

    return buildQueryString(mergedSearchParams)
}

const apiFunctions = {
    getApi,
    postApi,
    putApi,
    deleteApi,
    downloadGetApi,
    handleApiResponseMessage,
    toQueryString,
}

export default apiFunctions
