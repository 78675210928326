import { Uom } from "@/types/Uom"
import { VariationOptionCombinations } from "@/types/Variation"
import Decimal from "decimal.js"
import { orderBy } from "lodash"

export const convertNullToUndefined = (obj: Record<string, any>) => {
    const newObj: Record<string, any> = {}
    for (const [key, value] of Object.entries(obj)) {
        newObj[key] = value === null ? undefined : value
    }
    return newObj
}

export const createFilters = <T extends Record<string, any>>(filters: T) => {
    return Object.keys(filters).map((key) => `${key}:${filters[key as keyof T]}`)
}

export const getURLParams = (getData: Record<string, any>) => {
    const urlParams = new URLSearchParams()
    for (const [key, value] of Object.entries(getData)) {
        if (Array.isArray(value)) {
            value.forEach((v) => {
                urlParams.append(`${key}[]`, v)
            })
        } else {
            urlParams.append(key, value)
        }
    }
    return urlParams
}

export const getValueFromPath = (value: any, path: string) => {
    // Replace square bracket notation with dot notation
    const normalizedPath = path.replace(/\[(\w+)\]/g, ".$1")

    const pathArray = normalizedPath.split(".")
    let result = value
    for (const p of pathArray) {
        if (result) {
            result = result[p]
        } else {
            break
        }
    }
    return result
}

export const getItemVariantName = (varCombo?: VariationOptionCombinations) => {
    let variantName = "No variant"

    if (varCombo?.variation_option_value01) {
        variantName = varCombo.variation_option_value01.name
    }

    if (varCombo?.variation_option_value02) {
        variantName += ` - ${varCombo.variation_option_value02.name}`
    }

    return variantName
}

export const getBaseUom = (uoms?: Uom[]) => {
    if (!uoms) return undefined

    if (uoms.length > 0) {
        const baseUom = uoms.reduce((prev, current) => {
            if (prev.pivot && current.pivot) {
                return prev.pivot.uom_rate < current.pivot.uom_rate ? prev : current
            }

            return prev
        }, uoms[0])

        return baseUom
    }

    return undefined
}

export interface QuantityUom {
    baseQuantity: string | number
    baseUom?: Uom
    highestQuantity?: string | number
    highestUom?: Uom
}

export const renderUomQuantities = (quantity: string | number, uoms?: Uom[]) => {
    const quantityUom = getUomQuantities(quantity, uoms)

    if (!uoms || uoms.length === 0) return quantityUom.baseQuantity

    if (!quantityUom.highestUom && quantityUom.baseUom) return `${quantityUom.baseQuantity} ${quantityUom.baseUom.code}`

    if (quantityUom.highestUom && !quantityUom.baseUom) return `${quantityUom.highestQuantity} ${quantityUom.highestUom.code}`

    if (quantityUom.highestUom && quantityUom.baseUom)
        return `${quantityUom.highestQuantity} ${quantityUom.highestUom.code}${quantityUom.baseUom ? ` ${quantityUom.baseQuantity} ${quantityUom.baseUom.code}` : ""}`
}

export const getUomQuantities = (quantity: string | number, uoms?: Uom[]): QuantityUom => {
    if (!uoms || uoms.length === 0) return { baseQuantity: quantity, highestQuantity: 0 }

    if (uoms.length === 1) {
        return {
            baseQuantity: 0,
            highestQuantity: quantity,
            highestUom: uoms[0],
        }
    }

    const sortedUoms = orderBy(uoms, (uom) => Number(uom.pivot?.uom_rate ?? 1), "desc")

    let unitQuantity = new Decimal(quantity).abs()
    let uomQuantity: QuantityUom = {
        baseQuantity: 0,
        baseUom: undefined,
        highestQuantity: 0,
        highestUom: undefined,
    }

    sortedUoms.some((uom, index) => {
        if (uom.pivot) {
            const uomRate = new Decimal(uom.pivot.uom_rate)
            const convertedQuantity = unitQuantity.dividedToIntegerBy(uomRate)
            if (convertedQuantity.toNumber() >= 1) {
                if (index < uoms.length - 1) {
                    if (uom.allow_decimal) {
                        uomQuantity = {
                            baseQuantity: 0,
                            baseUom: uoms[0],
                            highestQuantity: convertedQuantity.toFixed(6),
                            highestUom: uom,
                        }

                        unitQuantity = new Decimal(0)
                    } else {
                        unitQuantity = unitQuantity.modulo(uomRate)

                        uomQuantity = {
                            ...uomQuantity,
                            highestQuantity: convertedQuantity.toNumber(),
                            highestUom: uom,
                        }
                    }

                    return true
                }
            }
        }

        return false
    })

    if (unitQuantity.toNumber() > 0) {
        uomQuantity = {
            ...uomQuantity,
            baseQuantity: unitQuantity.toNumber(),
            baseUom: sortedUoms[sortedUoms.length - 1],
        }
    }

    return uomQuantity
}

export const getUnitQuntity = (quantity: string | number, uom: Uom | undefined) => {
    if (uom?.pivot) {
        const uomRate = new Decimal(uom.pivot.uom_rate)
        return new Decimal(quantity).mul(uomRate).toNumber()
    }

    return quantity
}

export interface Address {
    unit_no: string
    building: string
    street: string
    city: string
    state: string
    country: string
    postcode: string
}

export const getAddress = (address: Address) => {
    return `${address.unit_no ? `${address.unit_no}, ` : ""}${address.building ? `${address.building}, ` : ""}${
        address.street ? `${address.street}, ` : ""
    }${address.city ? `${address.city}, ` : ""}${address.state ? `${address.state}, ` : ""}${address.country ? `${address.country}, ` : ""}${address.postcode}`
}

export const getUomQuantitiesDesireUom = (quantity: string | number, uoms?: Uom[], desireUomId?: number): QuantityUom => {
    if (!uoms || uoms.length === 0) return { baseQuantity: quantity, highestQuantity: 0 }

    if (uoms.length === 1) {
        return {
            baseQuantity: 0,
            highestQuantity: quantity,
            highestUom: uoms[0],
        }
    }

    const sortedUoms = orderBy(uoms, (uom) => Number(uom.pivot?.uom_rate ?? 1), "desc")
    const ascSortedUoms = orderBy(uoms, (uom) => Number(uom.pivot?.uom_rate ?? 1), "asc")

    let unitQuantity = new Decimal(quantity).abs()
    let uomQuantity: QuantityUom = {
        baseQuantity: 0,
        baseUom: ascSortedUoms[0],
        highestQuantity: 0,
        highestUom: undefined,
    }

    if (desireUomId === ascSortedUoms[0]?.id) {
        uomQuantity = {
            ...uomQuantity,
            highestQuantity: unitQuantity.toNumber(),
            highestUom: ascSortedUoms[0],
        }

        return uomQuantity
    }

    sortedUoms.some((uom, index) => {
        if (uom.pivot) {
            const uomRate = new Decimal(uom.pivot.uom_rate)
            const convertedQuantity = unitQuantity.dividedToIntegerBy(uomRate)

            if (!desireUomId || desireUomId === uom.id) {
                if (index < uoms.length - 1) {
                    if (uom.allow_decimal) {
                        uomQuantity = {
                            baseQuantity: 0,
                            baseUom: uoms[0],
                            highestQuantity: convertedQuantity.toFixed(6),
                            highestUom: uom,
                        }

                        unitQuantity = new Decimal(0)
                    } else {
                        unitQuantity = unitQuantity.modulo(uomRate)

                        uomQuantity = {
                            ...uomQuantity,
                            highestQuantity: convertedQuantity.toNumber(),
                            highestUom: uom,
                        }
                    }

                    return true
                }
            }
        }

        return false
    })

    if (unitQuantity.toNumber() > 0) {
        uomQuantity = {
            ...uomQuantity,
            baseQuantity: unitQuantity.toNumber(),
            baseUom: sortedUoms[sortedUoms.length - 1],
        }
    }

    return uomQuantity
}

export const formatUomQuantities = (uomQuantityItem: QuantityUom): string => {
    return `${Number(uomQuantityItem.highestQuantity).toFixed(0) ?? " "} ${uomQuantityItem.highestUom?.code}${
        Number(uomQuantityItem.baseQuantity) > 0 ? ` ${Number(uomQuantityItem.baseQuantity).toFixed(0) ?? ""} ${uomQuantityItem.baseUom?.code}` : ""
    }`
}
